import React, { useEffect, useRef, useState } from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import svLocale from "date-fns/locale/sv";
import Header from "./Header";
import Footer from "./Footer";
import { useStyles } from "./MOLayout.style";
import { usePageContext } from "../Context/PageContextProvider";
import FontLoader from "../../Infrastructure/Fonts/FontLoader";
import useHashFragment from "../../hooks/useHashFragment";
import useOnLoadImages from "../../hooks/useOnLoadImages";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import OpenPagesConfig from "app/Infrastructure/OpenPagesConfig";
import OneTrustCookiesConsentScriptLoader from "../Service/OneTrustCookiesConsentScriptLoader";
export const MOLayout = (props) => {
    const { brand, startPageData, hideFooter } = usePageContext();
    const classes = useStyles();
    const [lang, setLang] = useState(startPageData?.language?.name);
    const [oneTrustScriptDomainId, setOneTrustScriptDomainId] = useState(startPageData?.oneTrustScriptDomainID?.value);
    const wrapperRef = useRef(null);
    const imagesLoaded = useOnLoadImages(wrapperRef);
    useHashFragment(imagesLoaded);
    useEffect(() => {
        setLang(startPageData?.language?.name);
        setOneTrustScriptDomainId(startPageData?.oneTrustScriptDomainID?.value);
        const serverSideStyles = document.querySelectorAll("#server-side-styling");
        if (serverSideStyles) {
            const canonicalLink = document.querySelector("link[rel='canonical']");
            const isNotFound = canonicalLink && canonicalLink.getAttribute("href").includes("/404");
            if (!isNotFound) {
                serverSideStyles.forEach(function (item) {
                    item.remove();
                });
            }
        }
    }, [startPageData]);
    useEffect(() => {
        FontLoader(brand);
    }, [brand]);
    const GlobalCssLoader = () => {
        const classes = useStyles();
        return React.createElement("div", { className: classes.cssLoader });
    };
    return (React.createElement(GoogleReCaptchaProvider, { reCaptchaKey: OpenPagesConfig.recaptchaKey, useEnterprise: true },
        React.createElement("div", { id: "main", className: classes.layout, ref: wrapperRef },
            React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils, locale: svLocale },
                React.createElement(GlobalCssLoader, null),
                React.createElement(OneTrustCookiesConsentScriptLoader, { language: lang, domainId: oneTrustScriptDomainId }),
                React.createElement(Header, null),
                React.createElement("div", { className: classes.skeletonBody }, props.children),
                !hideFooter && React.createElement(Footer, null)))));
};
export default MOLayout;
